.footer-logos {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    width: 100%;
    
  }

  .footer-logo:hover {
    filter: none;
  }
  
  .logos-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1500px;
    max-width: 1600px;
    gap: 30px; /* Spacing between logos */
    flex-wrap: wrap; /* Ensures wrapping on smaller screens */
    background: linear-gradient(to bottom, rgb(206, 251, 232),rgb(255, 255, 255))

  }
  
  .footer-logo {
    height: 50px; /* Adjust height as needed */
    max-width: 100px; /* Prevents logos from being too large */
    object-fit: contain; /* Ensures proper resizing */
    filter: grayscale(1.0);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .logos-container {
      flex-direction: column; /* Stack logos vertically on small screens */
      gap: 15px;
    }
  
    .footer-logo {
      height: 40px; /* Adjust height for mobile */
      max-width: 80px;
      filter: none;
    }
  }
  