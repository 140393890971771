.portfolio-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.portfolio-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    border-radius: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
    background-color: white;
}

.portfolio-card:hover {
    transform: scale(1.02);
}

.portfolio-image {
    height: 200px;
    width: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 10px 10px 0 0;
}

.portfolio-text {
    padding: 15px;
    flex-grow: 1;
}


.portfolio-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.portfolio-card {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
    background-color: white;
    min-height: 200px;
}

.portfolio-card:hover {
    transform: scale(1.02);
}

.portfolio-image {
    background-size: cover;
    background-position: center;
}

.portfolio-text {
    flex: 60%; /* Text takes up 60% */
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
