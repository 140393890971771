.services-section {
    padding: 80px 0;
    text-align: center;
}

.cyl-services {
    width: 100px;
    left: 1px;
    top: 800px
}

.circle-services {
    width: 100px;
     right: 1px;
}

/* Heading */
.bold {
    font-weight: bold;
    color: #04724D;
}

/* Partner Logos */
.partner-logo {
    height: 100px;
    filter: grayscale(1.0);
}

.partner-logo:hover {
    filter: none;
}

/* Seamless Integration Features */
.integration-features {
    margin-top: 30px;
}

.feature-icon {
    width: 60px;
    height: auto;
    margin-bottom: 15px;
    filter: grayscale(1.0);
}

h5 {
    font-weight: 600;
}

p {
    color: #555;
    font-size: 16px;
}

a {
    display: inline-block;
    color: #04724D;
    font-weight: 600;
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}
