.projects-supported-card {
    padding: 20px;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .projects-supported-title {
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 10px;
  }
  
  .projects-list {
    list-style: none;
    padding: 0;
  }
  
  .project-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
  }
  
  .project-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .project-name {
    flex-grow: 1;
  }
  
  .project-percentage {
    font-weight: bold;
    color: #333;
  }
  
  .view-projects-btn {
    width: 100%;
    background-color: #05a680;
    color: white;
    transition: 250ms;
    border: #05a680;
  }
  
  .view-projects-btn:hover {
    background-color: #fff;
    color: #05a680;
    border: 1px solid #05a680;
  }
  