/* DevelopersPage.css */
.container {
    max-width: 100%; /* Full width for mobile */
    padding: 0 15px; /* Add padding for mobile devices */
}

.card {
    border: none;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    margin-bottom: 1rem; /* Add margin at the bottom for better spacing on mobile */
}

button {
    font-weight: bold;
}

h2 {
    font-family: 'Times New Roman', Times, serif;
    color: #000;
}

p {
    color: #555;
    font-size: 1rem;
}

.text-right {
    text-align: right;
}

.d-flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}

.justify-content-end {
    justify-content: flex-end;
}

.toggle-container {
    display: flex;
    align-items: center; /* Aligns the toggle and text vertically */
    justify-content: flex-end; /* Aligns items to the right */
}

.toggle-text {
    margin-right: 10px; /* Space between the text and the toggle */
    font-size: 14px; /* Adjust the text size if needed */
}

.custom-toggle {
    display: inline-block;
    width: 40px; /* Width of the toggle */
    height: 20px; /* Height of the toggle */
    border-radius: 10px;
    background-color: #ccc;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.custom-toggle.active {
    background-color: #28a745; /* Color when active */
}

.custom-toggle .slider {
    width: 18px; /* Smaller slider width */
    height: 18px; /* Smaller slider height */
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 1px; /* Centered vertically */
    left: 1px; /* Initial position */
    transition: all 0.3s ease;
}

.custom-toggle.active .slider {
    left: 21px; /* Moves slider to the right when active */
}

.api-keys-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    font-size: 1rem;
}

.api-keys-table thead tr {
    background-color: #f0f8f5; /* Light green background */
    color: #000;
    font-weight: bold;
    text-align: left;
}

.api-keys-table th, .api-keys-table td {
    padding: 12px 15px;
    word-wrap: break-word; /* Break long words to prevent overflow */
}

.api-key {
    display: flex;
    align-items: center;
    font-weight: bold;
}

.show-button {
    margin-left: 10px;
    font-size: 0.9rem;
    padding: 0;
    text-transform: uppercase;
}

.status-badge {
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #28a745;
    color: white;
}

.date-column {
    color: #6c757d;
    font-size: 0.9rem;
}

.actions-column {
    display: flex;
    gap: 10px;
    flex-wrap: wrap; /* Allow actions to stack on smaller screens */
}

.rotate-button, .revoke-button {
    border-radius: 30px;
    padding: 5px 15px;
    font-size: 0.9rem;
    font-weight: bold;
}

.rotate-button {
    color: #007bff;
    border: 1px solid #007bff;
    border-radius: 30px;
}

.rotate-button:hover {
    background-color: #007bff;
    color: white;
}

.revoke-button {
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 30px;
    
}

.revoke-button:hover {
    background-color: #c82333;
}

.show-button {
    display: inline-flex;
    align-items: center;
    padding: 5px 12px; /* Padding for the button */
    background-color: #f0f5fa; /* Light blue background */
    border-radius: 10px; /* Rounded corners */
    border: none;
    font-weight: bold;
    color: #333; /* Dark text color */
    font-size: 0.9rem; /* Slightly smaller font size */
    text-transform: uppercase;
    box-shadow: none;
    transition: background-color 0.3s ease;
}

.show-button:hover {
    transform: scale(1.2);
}

.show-icon {
    margin-right: 8px; /* Space between icon and text */
    color: #333; /* Dark color for the icon */
}

@media (max-width: 768px) {
    .api-keys-table th, .api-keys-table td {
        display: block; /* Make table rows stack on smaller screens */
        width: 100%;
        box-sizing: border-box;
    }

    .date-column, .actions-column {
        text-align: left; /* Align text to the left on small screens */
    }

    .toggle-container {
        justify-content: flex-start;
    }

    .text-right {
        text-align: left;
    }
}
