.company-page {
  padding: 50px 0;
  background: linear-gradient(to bottom, white, rgb(206, 251, 232), white);
}

.about-us-section {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
}

.about-us-content h1 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #111;
}

.about-us-content .highlight {
  color: #007a5e;
}

.about-us-content p {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 10px;
}

/* Team Section */
.team-section {
  margin-top: 40px;
}

/* Team Member Cards */
.team-member {
  text-align: center;
  margin: 20px;
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
  padding: 10px;
}

.team-member img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.team-member h3 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  color: #222;
}

.team-member p {
  font-size: 14px;
  color: #666;
}

/* Social Media Icons */
.team-member .social-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 8px;
}

.team-member .social-icons a {
  background: #007a5e;
  color: white;
  padding: 6px;
  border-radius: 50%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
