/* impactShare.css */

@media (max-width: 768px) {
  .impact-title {
      font-size: 1.8rem;
  }

  .impact-value {
      font-size: 1.5rem;
  }

  .impact-item h3 {
      font-size: 1.2rem;
  }

  .impact-item p {
      font-size: 0.9rem;
  }

  .right-section {
      margin-top: 20px;
  }

  .share-icons a {
      font-size: 1.2rem;
  }
}

@media (max-width: 576px) {
  .impact-title {
      font-size: 1.5rem;
  }

  .impact-value {
      font-size: 1.2rem;
  }

  .impact-item {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .impact-item h3 {
      font-size: 1rem;
  }

  .impact-item p {
      font-size: 0.8rem;
  }

  .right-section {
      margin-top: 15px;
  }

  .share-icons a {
      font-size: 1rem;
  }
}

.sustainable-goals {
  padding: 30px;
  background-color: #f5f5f5; /* Light grey background */
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.item-icon {
    color: black;
    transform: scale(1.2);
}

.item-icon:hover {
    color: cornflowerblue;
}