.pricing-section {
    padding: 80px 0;
    background: linear-gradient(to bottom, #e8f4ef, #ffffff);
    text-align: center;
}

.pricing-cards-container {
    display: flex;
    justify-content: center;
}

.pricing-box {
    background: #fff;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;
}

.pricing-box:hover {
    transform: translateY(-5px);
}

.gradient-background {
    background: linear-gradient(to bottom, #ffffff, #e8f4ef);
}

.plan-title {
    font-size: 16px;
    font-weight: 600;
    color: #8a8a8a;
}

.plan-price {
    font-size: 32px;
    font-weight: bold;
    color: #000;
}

.plan-subtitle {
    font-size: 16px;
    font-weight: 400;
    color: #8a8a8a;
}

.pricing-button {
    display: block;
    background: #1a774e;
    color: white;
    padding: 10px 15px;
    border-radius: 8px;
    text-decoration: none;
    margin: 20px auto;
    width: 80%;
    font-weight: bold;
}

.pricing-button:hover {
    background: #145f3b;
}

.features-list {
    list-style: none;
    padding: 0;
    margin-top: 20px;
}

.features-list li {
    text-align: left;
    padding: 5px 0;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.features-list li::before {
    content: "✔";
    color: #1a774e;
    font-weight: bold;
    margin-right: 10px;
}
