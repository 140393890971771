.impact-metrics-container {
    padding: 30px;
    background-color: #faf9f6;
}

.top-section {
    margin-bottom: 30px;
}

.title {
    font-size: 28px;
    font-weight: bold;
}

.subaccount-dropdown,
.date-range-button,
.download-button {
    margin-right: 15px;
}

.total-section {
    margin-bottom: 30px;
}

.total-card {
    background-color: #f7f8fa;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.total-description {
    color: #6b7280;
}

.total-value {
    font-size: 48px;
    font-weight: bold;
    color: #1f2937;
}

.impact-section {
    margin-bottom: 30px;
}

.impact-card {
    background-color: #f7f8fa;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.impact-icon {
    font-size: 30px;
    color: #6b7280;
    margin-bottom: 10px;
}

.statistics-section {
    margin-top: 30px;
}

.stat-chart {
    height: 100px;
    border-radius: 5px;
    margin-top: 10px;
}
