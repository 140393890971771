.billing-section {
    margin-bottom: 40px;
  }
  
  .billing-card {
    margin-top: 10px;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  }
  
  .billing-card h4 {
    font-weight: bold;
  }
  
  .billing-card p {
    color: #666;
    margin-bottom: 20px;
  }
  
  .btn-change {
    background-color: #000;
    color: #fff;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 14px;
  }
  
  .status-paid {
    color: #4caf50;
    font-weight: bold;
  }
  
  .table {
    margin-top: 20px;
  }
  
  .table th {
    font-weight: bold;
    color: #333;
  }
  
  .table td {
    color: #666;
  }
  
  .table td i {
    font-size: 12px;
    color: #ff5722;
    margin-left: 4px;
  }
  