.order-page {
    padding: 20px;
    background-color: #f8f9fa;
    font-family: 'Helvetica Neue', sans-serif;
  }
  
  .order-header {
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
  
  .order-id {
    font-size: 1.25rem;
    color: #333;
  }
  
  .order-id-value {
    color: #007bff;
  }
  
  .order-title {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-top: 10px;
  }
  
  .order-details-list {
    list-style: none;
    padding: 0;
    margin-top: 20px;
  }
  
  .order-details-list li {
    font-size: 1rem;
    color: #666;
    margin-bottom: 10px;
  }
  
  .fulfilled-section {
    background-color: #f0faf4;
    padding: 20px;
    color: white;
  }
  
  .fulfilled-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .fulfilled-projects-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  
  .fulfilled-project {
    background-color: #defcf1;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #ddd;
  }
  
  .project-name-details {
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 1px;
  }
  
  .project-type-details {
    font-size: 1rem;
    color: #718096;
    margin-bottom: 1px;
  }
  
  .project-details {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .project-details li {
    font-size: 0.9rem;
    color: #000000;
    margin-bottom: 5px;
  }
  .fulfilled-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .fulfilled-projects-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  
  .fulfilled-card {
    background-color: #36e4c5;
    color: white;
    padding: 15px;
    border-radius: 15px;
    min-width: 280px;
    max-width: 300px;
  }
  
  .project-name {
    font-size: 18px;
    font-weight: bold;
  }
  
  .project-delta {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .project-location, .project-registry, .project-type {
    font-size: 14px;
    margin: 3px 0;
  }
  
  .project-registry a {
    color: white;
    text-decoration: underline;
  }
  