.project-details {
  font-family: 'Poppins', sans-serif;
}

.back-link {
  text-decoration: none;
  display: inline-block;
  margin-bottom: 20px;
}
.goal-tooltip {
  visibility: hidden;
  opacity: 0;
  width: 120px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #000000;
  text-align: center;
  padding: 6px;
  border-radius: 6px;
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.2s, visibility 0.2s;
  font-size: 12px;
  z-index: 1;
}

.goal-container:hover .goal-tooltip {
  visibility: visible;
  opacity: 1;
}
.back-button {
  font-weight: 500;
  border: 1px solid #0f766e;
  color: #0f766e;
  background-color: transparent;
  border-radius: 8px;
  padding: 8px 16px;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #0f766e;
  color: white;
}

.project-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.project-type, .project-developer, .project-vintage {
  font-size: 1.1rem;
  color: #444;
  margin-bottom: 0.8rem;
  display: flex;
  align-items: center;
}

.label {
  font-weight: 600;
  margin-right: 8px;
}

.project-image {
  width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.description-card, .ratings-card, .carbon-registry-card, .sustainable-goals-card, .location-card {
  border-radius: 15px;
  border: none;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  padding: 15px;
}

.goals {
  display: flex;
  gap: 10px;
}

.goal-image {
  width: 70px;
  height: auto;
  border-radius: 5px;
}

.map-placeholder {
  width: 100%;
  height: 150px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .project-title {
    font-size: 2rem;
  }

  .project-image {
    width: 100%;
    height: auto;
  }

  .description-card, .ratings-card, .carbon-registry-card, .sustainable-goals-card, .location-card {
    margin-bottom: 20px;
  }
}
.project-details {
  font-family: 'Poppins', sans-serif;
}

.back-link {
  text-decoration: none;
  display: inline-block;
  margin-bottom: 20px;
}

.back-button {
  font-weight: 500;
  border: 1px solid #0f766e;
  color: #0f766e;
  background-color: transparent;
  border-radius: 8px;
  padding: 8px 16px;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #0f766e;
  color: white;
}

.project-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #2e3d49;
}

.project-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.project-type, .project-developer, .project-vintage {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: #4a5568;
}

.label {
  font-weight: 600;
  margin-left: 8px;
}

.project-icon {
  font-size: 1.5rem;
  color: #4a5568;
  margin-right: 10px;
}

.project-image {
  width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.project-header {
  position: relative;
  width: 100%;
  height: 350px;  /* Adjust height as needed */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-header-overlay {
  background: rgba(0, 0, 0, 0.6); /* Dark overlay for better readability */
  color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  width: 100%;
}

.wf {
  color: white;
}