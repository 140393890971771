.home-section {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    overflow: hidden;
    background: linear-gradient(to bottom, rgb(243 255 250),rgb(206, 251, 232))
}

.bg-image {
    position: absolute;
    z-index: 0;
    opacity: 0.8;
}

.star {
    top: 10%;
    right: 10%;
    width: 400px;
    animation: float 3s ease-in-out infinite;
}

.cyl {
    top: 10%;
    left: 50%;
    width: 120px;
}

.arch {
    bottom: 5%;
    right: 5%;
    width: 200px;
}

.text-content {
    max-width: 500px;
}

.bold {
    font-weight: bolder;
    color: #000000;
}

.button-group {
    margin-top: 20px;
}

.btn-primary {
    background-color: #026e57;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    color: white;
}

.btn-secondary {
    background-color: transparent;
    border: 2px solid #026e57;
    padding: 10px 20px;
    border-radius: 8px;
    color: #026e57;
    margin-left: 10px;
}
  

@keyframes float {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

  /* Responsive Design */
  @media (max-width: 768px) {
    .star {
        width: 200px;
    }
  }
  