/* General Styling */
.bg-home5 {
    background-color: #f8f9fa;
    padding: 40px 0;
    color: #333;
}

h1, h2, h3, h4, p {
    margin-bottom: 20px;
}

h1, h2 {
    font-weight: bold;
}

.disclaimer {
    background-color: #fff3cd;
    padding: 10px;
    border-radius: 4px;
}

/* Chart Section */
.chart-section {
    text-align: center;
    margin-bottom: 30px;
    background-color: white;
    padding: 3px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.chart-section h3 {
    margin-bottom: 20px;
}

.chart-section .btn {
    margin-top: 10px;
}


/* Scope Info Section */
.scope-info-section {
    margin-top: 30px;
}

.scope-info-section h4 {
    font-weight: bold;
    margin-bottom: 10px;
}

.scope-info-section p {
    font-size: 14px;
}

/* Action Section */
.action-section {
    margin-top: 30px;
    text-align: center;
}

.action-button {
    width: 100%;
    margin-bottom: 10px;
}

.sub-section .sub-chart-section {
    background-color: white; /* White background for chart containers */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    min-height: 400px; 
}

.sub-section .sub-chart-section h2 {
    margin-bottom: 20px;
}

.sub-section .sub-chart-section .mixed-chart {
    max-width: 100%; /* Ensure the chart does not exceed the container width */
}

.sub-section .sub-chart-section .apexcharts-canvas {
    max-width: 400px; /* Consistent max width for charts */
    margin: 0 auto; /* Center align charts */
}

.action-section, .benchmark-section {
    margin-top: 40px;
    padding: 30px;
    background-color: #f5f5f5; /* Light grey background */
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.action-section h3, .benchmark-section h3 {
    font-size: 1.6rem;
    color: #333; /* Dark text color for titles */
    margin-bottom: 15px;
}

.add-time-btn {
    float: right;
    border-radius: 10px;
    background-color: #05a680;
    color: white;
    transition: 250ms;
    border: #05a680;
    padding: 0;
  }
  
  .add-time-btn:hover {
    background-color: #f0f0f0;
    color: black;
  }

  .small-button {
    font-size: 0.8rem;  /* Adjust the font size as needed */
    padding: 3px 8px; /* Adjust the padding as needed */
    border-radius: 60px; /* Optional: adjust the border radius */
    margin: 2px; /* Small margin to separate buttons */
    
    align-self: center;
    transition: 250ms;
    background-color: #f0f0f0;
    border: #f0f0f0;

  }


.action-section p, .benchmark-section p {
    font-size: 1rem;
    color: #666; /* Muted text color */
}

.action-section .action-button, .benchmark-section .benchmark-button {
    margin-top: 20px;
    width: 100%;
    max-width: 220px;
    font-size: 1rem;
    padding: 10px;
}

.action-button {
    background-color: #1f8b4c; /* Green color for the primary action button */
    border-color: #1f8b4c;
}

.benchmark-button {
    background-color: #0a74da; /* Blue color for the secondary action button */
    border-color: #0a74da;
}

.action-section, .benchmark-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .action-section, .benchmark-section {
        flex-direction: column;
        align-items: flex-start;
    }

    .action-section .action-button, .benchmark-section .benchmark-button {
        width: 100%;
        margin-top: 10px;
    }
}

@media (max-width: 576px) {
    .bg-home5 {
        padding: 20px 0;
    }

    h1, h2, h3, h4, p {
        margin-bottom: 10px;
    }

    .chart-section, .sub-section .sub-chart-section {
        padding: 15px;
        border-radius: 10px;
    }

    .action-section, .benchmark-section {
        padding: 20px;
        margin-top: 20px;
    }

    .action-section h3, .benchmark-section h3 {
        font-size: 1.4rem;
    }

    .action-section p, .benchmark-section p {
        font-size: 0.9rem;
    }

    .action-section .action-button, .benchmark-section .benchmark-button {
        max-width: 100%;
        padding: 8px;
    }
}

.custom-dropdown {
    background-color: white;
    padding: 10px;
    border-radius: 5px;
}

.buttons {
    margin-left: auto;
}

.buttons .btn {
    margin-right: 15px; /* Adjust space between buttons */
}

.buttons .btn:last-child {
    margin-right: 0; /* Remove margin from the last button */
}

.btn {
    padding: 0;;
}


.buttons {
    display: flex;
    justify-content: flex-end; /* Aligns buttons to the right */
    min-width: 200px; /* Minimum width to keep the structure */
    max-width: 50%; /* Maximum width to prevent overexpansion, adjust as needed */
    margin-right: auto; /* Keeps the group right-aligned in the column */
}

/* Ensuring responsiveness */
@media (max-width: 768px) {
    .buttons {
        justify-content: center; /* Centers buttons on smaller screens */
        max-width: 100%; /* Allows buttons to use full width on smaller screens */
        min-width: 100px; /* Smaller minimum width for mobile devices */
    }
}

/* Handling very small devices */
@media (max-width: 576px) {
    .buttons {
        flex-direction: column; /* Stacks buttons vertically */
        align-items: center; /* Center alignment for a cleaner look */
        max-width: none; /* Removes max width restriction */
    }
}

.purchase-credits-btn {
    float: right;
  }

  .dashboard {
    font-family: "Jost", sans-serif;
    padding-top: 20px;
    
  }