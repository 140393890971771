.blog-section {
    padding: 60px 0;
  }
  
  .header-title h3 {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
  }
  
  .header-title p {
    text-align: center;
    color: #6c757d;
  }
  
  /* Featured Blog */
  .featured-blog {
    background: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .blog-header {
    display: flex;
    gap: 15px;
    margin-bottom: 10px;
  }
  
  .blog-logo {
    height: 40px;
  }
  
  .blog-image-wrapper {
    display: block;
  }
  
  .blog-image {
    width: 100%;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .blog-image:hover {
    transform: scale(1.02);
  }
  
  .blog-content {
    padding: 15px 0;
  }
  
  .blog-date {
    color: #6c757d;
    font-size: 14px;
  }
  
  .blog-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .blog-subtitle {
    font-size: 14px;
    color: #6c757d;
  }
  
  /* Articles List */
  .article-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .article-item {
    display: flex;
    background: #f8f9fa;
    padding: 10px;
    border-radius: 10px;
    align-items: center;
    text-decoration: none;
    transition: background 0.3s ease;
  }
  
  .article-item:hover {
    background: #e9ecef;
  }
  
  .article-image {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    margin-right: 15px;
  }
  
  .article-content {
    flex: 1;
  }
  
  .article-date {
    color: #6c757d;
    font-size: 12px;
  }
  
  .article-title {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
  }
  