/* src/assets/css/purchaseCarbonCredit.css */

.purchase-container {
    margin-top: 50px;
    padding: 20px;
    border-radius: 8px;
    
}

.donut-chart {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background: conic-gradient(#6C757D 75%, #FFC107 25%);
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.donut-chart h2 {
    margin-bottom: 10px;
    font-size: 24px;
}

.change-portfolio {
    color: #FF5722;
    cursor: pointer;
}

.learn-more {
    color: #FF5722;
    margin-left: 5px;
}

.emission-button,
.removal-button {
    width: 80%;
    font-size: 16px;
    text-align: left;
    padding-left: 10px;
}

.checkout-card {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.checkout-card .card-body {
    padding: 30px;
}

.checkout-card label {
    font-weight: bold;
}

.pricing-info p {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
}

.checkout-button {
    margin-top: 20px;
    font-size: 18px;
    background-color: #05bd93;
}

.cancel-button {
    color: #FF5722;
    text-align: center;
}
