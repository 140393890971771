.subaccounts-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #f9fffa;
}

.subaccounts-table thead th {
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    padding: 10px;
    background-color: #f0faf4;
    border-width: 1px;
    border-style: solid;
    border-image: initial;
    border-bottom: 2px solid #e0f0e0;
    color: #333;
}

.subaccounts-table tbody tr {
    transition: background-color 0.3s ease;
}

.subaccounts-table tbody tr:hover {
    background-color: #f2faf5;
}

.subaccounts-table tbody td {
    padding: 10px;
    font-size: 14px;
    color: #333;
    border-bottom: 1px solid #e0f0e0;
}

.subaccounts-table tbody td.id-column {
    color: #d9534f; /* Matches the red ID */
    font-weight: bold;
}

.subaccounts-table tbody tr:last-child td {
    border-bottom: none;
}