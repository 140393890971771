/* src/components/TimePeriodDropdown.css */
.custom-dropdown {
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ced4da;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 100%;
    position: relative; /* Ensure the dropdown menu aligns correctly */
}

.dropdown-toggle-custom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    width: 100%;
}

.dropdown-header {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 5px; /* Adjust font size */
}

.dropdown-header i {
    margin-right: 5px;
}

.dropdown-selected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 12px; /* Smaller font size for the dates */
    margin-top: 5px; /* Adjust spacing */
}

.dropdown-toggle-icon {
    margin-left: 10px;
    transition: transform 0.3s;
}

.dropdown-menu {
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    background-color: white;
    border: 1px solid #ced4da;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 0;
    margin-top: 5px; /* Adjust the position of the dropdown menu */
    display: none; /* Initially hide the dropdown menu */
}

.dropdown-menu.show {
    display: block;
}

.dropdown-menu .dropdown-item {
    padding: 10px;
    font-size: 12px; /* Smaller font size for the dropdown items */
    cursor: pointer;
}

.rotate {
    transform: rotate(180deg); /* Rotate the arrow when the dropdown is open */
}

.dropdown-menu .dropdown-item:hover {
    background-color: #f1f1f1;
}


/* Ensure dropdown and button group widths are controlled */
.dropdown-header, .buttons {
    min-width: 150px; /* Minimum width */
    max-width: 300px; /* Maximum width to prevent excessive stretching */
}

/* Enhance dropdown header and button responsiveness */
@media (max-width: 768px) {
    .dropdown-header, .buttons {
        max-width: 100%; /* Allows full width on smaller screens */
        flex-grow: 1; /* Ensures it fills the space available */
    }
}