/* General Section Styling */
.products-section {
    padding: 80px 0;
    background: linear-gradient(to bottom, white, rgb(232, 244, 239)); /* Subtle white-to-green gradient */
    text-align: center;
    position: relative;
}

/* Background Images */
.products-section .bg-image {
    position: absolute;
    z-index: -1;
}

.products-section .arch {
    left: -50px;
    top: 10%;
    width: 100px;
    opacity: 0.6;
}

.products-section .cyl {
    right: -50px;
    bottom: 5%;
    width: 100px;
    opacity: 0.6;
}

/* Title and Description */
.section-title {
    font-size: 2.2rem;
    font-weight: 700;
    color: #222;
    margin-bottom: 10px;
}

.section-description {
    font-size: 1rem;
    color: #555;
    max-width: 600px;
    margin: 0 auto 40px auto;
}

/* Grid Styling */
.products-grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

/* Product Box */
.product-box {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

/* Product Card */
.product-card {
    background: white;
    border-radius: 15px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
    padding: 30px;
    text-align: center;
    max-width: 350px;
    transition: transform 0.3s ease-in-out;
}

.product-card:hover {
    transform: translateY(-5px);
}

/* Product Image */
.product-image {
    width: 200px;
    height: auto;
    margin-bottom: 15px;
}

/* Product Title */
.product-title {
    font-size: 1.4rem;
    font-weight: 600;
    color: #034d3e;
    margin-bottom: 10px;
}

/* Product Description */
.product-description {
    font-size: 1rem;
    color: #555;
}

/* Responsive Design */
@media (max-width: 992px) {
    .product-card {
        max-width: 300px;
    }
}

@media (max-width: 768px) {
    .products-section {
        padding: 50px 20px;
    }

    .product-card {
        max-width: 100%;
        padding: 20px;
    }

    .section-title {
        font-size: 1.8rem;
    }

    .section-description {
        font-size: 0.9rem;
    }
}

@media (max-width: 576px) {
    .products-grid {
        flex-direction: column;
        align-items: center;
    }

    .product-card {
        width: 100%;
    }
}
