.contact-section {
    padding: 80px 0;
    background: linear-gradient(to bottom, white, rgb(206, 251, 232), white);
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
}

.contact-input {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.7);
    margin-bottom: 15px;
    font-size: 16px;
    outline: none;
    transition: all 0.3s ease-in-out;
}

.contact-input:focus {
    background: rgba(232, 244, 239, 1);
    box-shadow: 0 0 10px rgba(26, 119, 78, 0.2);
}

.message-box {
    height: 100px;
}

.contact-submit-btn {
    background: #1a774e;
    color: white;
    padding: 12px 24px;
    border-radius: 20px;
    font-size: 16px;
    width: 150px;
    display: block;
    margin-top: 10px;
    border: none;
    transition: background 0.3s;
}

.contact-submit-btn:hover {
    background: #145f3b;
}

.contact-object {
    max-width: 100%;
    height: auto;
    animation: float 3s ease-in-out infinite;
}

@keyframes float {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}
