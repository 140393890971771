.consulting-services {
    width: 100vw;
    background: linear-gradient(to bottom, white, rgb(211, 248, 233));
    padding: 50px 0;
    position: relative;
    text-align: center;
  }
  
  .section-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  
  .services-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .service-box {
    display: flex;
    justify-content: center;
  }
  
  .service-card {
    background: white;
    border-radius: 20px;
    padding: 25px;
    text-align: center;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    width: 100%;
    max-width: 320px;
    margin-bottom: 10px;
  }
  
  .service-card:hover {
    transform: translateY(-5px);
    animation: float 3s ease-in-out infinite;
    background: linear-gradient(to bottom, white, rgb(206, 251, 232), white);
  }
  
  .service-icon {
    width: 60px;
    height: 60px;
    margin-bottom: 15px;
  }
  
  .service-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .service-description {
    font-size: 14px;
    color: #666;
  }
  
  /* 🌐 Mobile Responsiveness */
  @media (max-width: 768px) {
    .services-grid {
      flex-direction: column;
      align-items: center;
    }
    .service-card {
      max-width: 90%;
    }
  }
  
  /* 🎨 Background Decorations */
  .bg-image {
    position: absolute;
    opacity: 0.3;
  }
  
  .arch {
    bottom: 20px;
    right: 30px;
    width: 80px;
  }
  
  .cyl {
    top: 30px;
    left: 20px;
    width: 80px;
  }
  
  @keyframes float {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}