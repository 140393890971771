/* Orders Page */
.dashboard {
    padding: 20px;
  }
  
  /* Title */
  .orders-title {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  /* Summary Cards */
  .orders-summary {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .summary-card {
    border-radius: 12px;
    background: #ffffff;
    display: flex;
    align-items: left;
    justify-content: left;
    text-align: left;
    padding: 0px 0px 0px 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  
  .summary-text {
    text-align: left;
  }
  
  .summary-text p {
    font-size: 14px;
    margin: 0;
    color: #555;
  }
  
  .summary-text h3 {
    font-size: 22px;
    font-weight: bold;
    color: #000000;
    margin: 0;
  }
  
  /* Orders Table */
  .orders-table-container {
    background: #ffffff;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .orders-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
  }
  
  .orders-table th {
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 8px;
  }
  
  .orders-table td {
    background: white;
    padding: 12px;
    border-radius: 8px;
    font-size: 14px;
  }
  
  .order-id {
    font-weight: bold;
    color: #333;
  }
  
  /* Status Badges */
  .status-badge {
    display: inline-block;
    padding: 5px 12px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .fulfilled {
    background-color: #34d399;
    color: white;
  }
  
  .disabled {
    background-color: #f87171;
    color: white;
  }
  
  .error {
    background-color: #facc15;
    color: black;
  }
  
  .default {
    background-color: #d1d5db;
    color: black;
  }

  .orders-summary {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .summary-card {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 15px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 180px;
  }
  
  .summary-card-body {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .summary-icon-circle {
    width: 40px;
    height: 40px;
    background-color: #2cc9a2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .summary-icon {
    color: white;
  }
  
  .summary-text p {
    margin: 0;
    font-size: 14px;
    color: #6c757d;
  }
  
  .summary-value {
    font-size: 18px;
    font-weight: bold;
    color: black;
  }
  
  .orders-table-container {
    border-radius: 15px;
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  }
  
  .orders-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }
  
  .orders-table thead tr {
    background-color: white;
    font-weight: bold;
  }
  
  .orders-table th {
    text-align: left;
    padding: 15px;
    color: #6c757d;
  }
  
  .orders-table tbody tr {
    background-color: white;
    border-radius: 10px;
  }
  
  .orders-table tbody tr td {
    padding: 15px;
    border-bottom: 1px solid #eef1f4;
  }
  
  .orders-table tbody tr:last-child td {
    border-bottom: none;
  }
  
  .order-id {
    font-weight: bold;
    color: #2c60c9;
    text-decoration: none;
  }
  
  .order-id:hover {
    text-decoration: underline;
  }
  
  .status-cell {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .status-icon {
    font-size: 18px;
    display: inline-flex;
    width: 22px;
    height: 22px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  
  .status-text {
    font-size: 14px;
    font-weight: bold;
  }
  
  .fulfilled .status-icon {
    background-color: #28a745;
    color: white;
  }
  
  .canceled .status-icon {
    background-color: #dc3545;
    color: white;
  }
  
  .placed .status-icon {
    background-color: #ffc107;
    color: black;
  }
  
  .status-circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px; /* Adjust as needed */
    height: 24px;
    border-radius: 50%;
    font-size: 14px;
    font-weight: bold;
    color: white;
  }
  
  /* Colors based on status */
  .status-circle.fulfilled {
    background-color: #2ecc71; /* Green */
  }
  
  .status-circle.canceled {
    background-color: #e74c3c; /* Red */
  }
  
  .status-circle.placed {
    background-color: #f1c40f; /* Yellow */
  }
  