.custom-card {
    background-color: #ecf9f4; /* Light beige background */
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.3s ease;
  }
  
  .custom-card:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
  }
  
  .icon-container {
    background-color: transparent;
    padding: 10px;
    border-radius: 50px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  
  .card-title {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .card-description {
    color: #555;
    font-size: 1rem;
  }
  
  @media (max-width: 768px) {
    .custom-card {
      margin-bottom: 20px;
    }
  }
  