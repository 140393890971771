/* Portfolio Title */
.portfolio-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  /* Category Filter Buttons */
  .category-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .category-buttons {
    display: flex;
    justify-content: flex-end; /* Align buttons to the right */
    margin-bottom: 20px;
  }
  
  .category-button {
    background-color: #ffffff;
    color: #007b55;
    border: 2px solid #007b55;
    margin: 5px;
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 14px;
    transition: all 0.2s ease-in-out;
  }
  
  .category-button:hover, .category-button.active {
    background-color: #007b55;
    color: white;
  }
  
  /* Projects Grid */
  .projects-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  /* Project Cards */
  .project-card {
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    overflow: hidden;
    cursor: pointer;
    border: none;
    background: white;
    position: relative;
  }
  
  .project-card:hover {
    transform: scale(1.03);
  }
  
  /* Project Image */
  .project-image {
    width: 100%;
    height: 160px;
    background-size: cover;
    background-position: center;
    border-radius: 12px 12px 0 0;
    position: relative;
  }
  
  /* Favorite Button (Top-Right Corner) */
  .favorite-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background: white;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  /* Developer Section */
  .developer-section {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
  }
  
  .developer-avatars {
    display: flex;
    gap: -5px;
  }
  
  .developer-avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid white;
  }
  
  /* Project Footer */
  .project-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  
  /* Price */
  .project-price {
    font-size: 16px;
    font-weight: bold;
    color: #007b55;
  }
  
  /* View Details Button */
  .view-details {
    background-color: #007b55;
    color: white;
    border: 2px solid #007b55;
    font-size: 14px;
    font-weight: bold;
    border-radius: 20px;
    padding: 8px 12px;
    transition: background 0.2s ease-in-out;
  }
  
  .view-details:hover {
    background-color: #007b55;
  }
  