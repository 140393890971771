/* General Styling */
.pricing-page {
  font-family: Arial, sans-serif;
  color: #333;
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
  background-color: #e6f8f4;
  padding-top: 100px;
}

/* Pricing Section */
.pricing-section {
  text-align: center;
  background-color: #e6f8f4;
  padding: 40px 20px;
  padding: 5px;
}

.pricing-section h2 {
  font-size: 28px;
  margin-bottom: 10px;
}

.pricing-section p {
  margin-bottom: 30px;
  font-size: 16px;
}

.pricing-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.pricing-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  max-width: 300px;
  text-align: left;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.pricing-card:hover {
  transform: translateY(-10px);
}

.pricing-card h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}

.pricing-card h1 {
  font-size: 28px;
  margin: 15px 0;
}

.pricing-card h1 span {
  font-size: 14px;
  color: #888;
}

.pricing-card ul {
  padding: 0;
  margin: 20px 0 0;
  list-style: none;
}

.pricing-card ul li {
  margin-bottom: 10px;
  font-size: 14px;
}

.pricing-card .btn-primary {
  display: block;
  background-color: #05bd93;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin: 20px 0;
}

.pricing-card .btn-secondary {
  display: block;
  background-color: transparent;
  color: #000;
  padding: 10px 20px;
  border: 1px solid #000;
  border-radius: 20px;
  cursor: pointer;
  margin: 20px 0;
}

.faq-section {
  margin: 50px auto;
  max-width: 900px;
}

.faq-category {
  margin-bottom: 40px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: white;
}

.faq-category h3 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.faq-item {
  margin-top: 10px;
}

.faq-item button {
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-item button:hover {
  color: #007bff;
}

.faq-item button:focus {
  outline: none;
}

.faq-item button[aria-expanded="true"]::after {
  transform: rotate(180deg);
}

.faq-item button::after {
  content: "▼";
  font-size: 0.8rem;
  color: #666;
  margin-left: 10px;
  transition: transform 0.2s ease-in-out;
}

.faq-item .faq-content {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #666;
  line-height: 1.5;
  display: none;
}

.faq-item .faq-content.open {
  display: block;
}