/* widgetPreview.css */
.cmass-impact-embed {
    background-color: #ffffff;
    border-radius: 10px;
    font-family: 'Arial', sans-serif;
    color: #2D3748;
    padding: 20px;
    max-width: 900px;
    margin: 40px auto;
}

.cmass-impact-embed .impact-container {
    text-align: center;
    padding: 20px;
    background-color: #defcf1;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
}

.cmass-impact-embed .impact-container h4 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #2D3748;
}

.cmass-impact-embed .impact-container p {
    font-size: 1.2rem;
    color: #4A5568;
}

.cmass-impact-embed .impact-container h2 {
    font-size: 3.5rem;
    font-weight: bold;
    margin: 10px 0;
}

.cmass-impact-embed .equivalents-section {
    text-align: center;
    margin-top: 40px;
}

.cmass-impact-embed .equivalents-section h2 {
    font-size: 1.8rem;
    margin-bottom: 30px;
    color: #2D3748;
}

.cmass-impact-embed .equivalents-row {
    display: flex;
    justify-content: space-evenly;
    gap: 20px;
}

.cmass-impact-embed .equivalents-item {
    width: 200px;
    background-color: #defcf1;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 20px;
}

.cmass-impact-embed .equivalents-item img {
    display: block;
    margin: 0 auto;
    max-width: 150px;
    border-radius: 10px;
}

.cmass-impact-embed .equivalents-item h3 {
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: 15px;
    color: #2D3748;
}

.cmass-impact-embed .equivalents-item p {
    font-size: 1rem;
    color: #4A5568;
}

.cmass-impact-embed .powered-by {
    text-align: right;
    font-size: 0.8rem;
    color: #BDBDBD;
    margin-top: 20px;
}

.main-cont {
    text-align: center;
    font-family: "Noto Sans", sans-serif;
    position: relative;
    line-height: 1.2;
    font-weight: 300;
    background-color: #defcf1;
    padding: 18px 16px;
    border-radius: 20px;
    max-width: 325px;
    width: 100%;
    margin: 0 auto;
}

.t1 {
    font-size: 1rem;
    margin-bottom: 10px;
}

.offset {
    font-size: 2.75rem;
    font-weight: 300;
}

.react-shadow-101wtpb {
    border-bottom: 1px solid rgb(17, 17, 17);
    margin: 16px 12px;
    align-self: stretch;
}

.kg {
    font-size: 1rem;
    font-weight: 300;
}
