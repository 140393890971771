.impact-sharing-page h2, h4, h5 {
    font-weight: bold;
}

.section-card {
    background-color: #f9f9f9;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
}

.preview-section {
    background-color: #fafafa;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin-top: 20px;
}

.preview-card {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: inline-block;
}

.embed-code {
    background-color: #1e1e1e;
    color: #fff;
    padding: 15px;
    border-radius: 5px;
    font-size: 14px;
    margin-bottom: 20px;
}
