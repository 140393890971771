.footer-section {
    background: #007a5e;
    padding: 40px 0;
    color: white;
  }
  
  .footer-logo {
    height: 50px;
    margin-bottom: 15px;
  }
  
  .footer-text {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
  }
  
  .footer-social-links {
    display: flex;
    gap: 15px;
    margin-top: 10px;
  }
  
  .social-icon {
    color: white;
    font-size: 20px;
    transition: 0.3s ease;
  }
  
  .social-icon:hover {
    color: #d1d1d1;
  }
  
  /* Footer Links */
  .footer-links {
    margin-top: 20px;
  }
  
  .footer-title {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  
  .footer-list {
    list-style: none;
    padding: 0;
  }
  
  .footer-list li {
    margin-bottom: 5px;
  }
  
  .footer-list li a {
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
    transition: 0.3s ease;
  }
  
  .footer-list li a:hover {
    color: white;
  }
  
  /* Footer Bottom */
  .footer-bottom {
    margin-top: 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 15px;
    text-align: center;
  }
  
  .footer-bottom p {
    margin: 0;
    font-size: 14px;
  }
  
  .footer-bottom .footer-link {
    color: rgba(255, 255, 255, 0.7);
    margin-left: 15px;
    text-decoration: none;
  }
  
  .footer-bottom .footer-link:hover {
    color: white;
  }
  